* {
  box-sizing: border-box !important;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0px;
  font-family: "Roboto", sans-serif !important;
}