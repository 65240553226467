.achievements-page {
    min-height: calc(100vh - 85px);
    width: 100%;
}

.achievement-page-bg {
    background-image: url('../../assets/achievement.jpg');
    background-repeat: no-repeat;
    width: 100%;
    height: 43vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.achievement-page-bg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35);
}

.achievement-page-heading {
    color: #f1f1f1;
    font-size: 50px;
    letter-spacing: 1.4px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none; 
    -ms-user-select: none; 
    z-index: 2;
}

.achievement-page-container {
    padding: 40px 100px 100px;
    width: 100%;
}

.achivement-page-sub-heading {
    color: green;
    display: inline-block;
    font-size: 25px;
    padding-bottom: 8px;
    border-bottom: solid 3px green;
    margin-bottom: 20px;
}

.achievement-section-content-container {
    margin-bottom: 35px;
}

.achievement-container {
    display: flex;
    align-items: center;
}

.achievement-icon {
    margin-right: 10px;
}

.achievement {
    font-size: 24px;
    color: #302c2c;
}

.slide-bar{
    display: flex;
    justify-content: center;
}

.achievement-section-slider-container {
    width: 90%;
}

.achievement-image-container {
    width: 100%;
}

.achievement-image {
    width: 100%;
    height: 500px;
}


@media screen and (max-width: 768px) {
    .achievement-page-bg {
        height: 35vh;
    }

    .achievement-page-heading {
        font-size: 40px;
    }

    .achievement-page-container {
        padding: 20px 18px 70px;
    }

    .achievement-icon {
        width: 12%;
        margin-right: 8px;
    }

    .achievement {
        font-size: 20px;
        width: 88%;
    }

    .achievement-image {
        height: 230px;
    }
}


@media screen and (min-width: 770px) and (max-width: 1170px) {
    .achivement-page-sub-heading {
        font-size: 23px;
    }

    .achievement {
        font-size: 21px;
    }

    .achievement-page-container {
        padding: 40px 30px 70px;
    }

    .achievement-image {
        height: 450px;
    }
}