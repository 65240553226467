.app-container {
    height: 100vh;
    background-color: #ffffff;
}

.stop-scroll {
    overflow: hidden;
}

.link-element {
    text-decoration: none;
    color: none;
}

 a {
    text-decoration: none;
 }

 .whatsapp-icon {
    position: fixed;
    background-color: #22C56B;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    width: 55px;
    border: none;
    border-radius: 50%;
    bottom: 30px;
    left: 40px;
    z-index: 10;
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.2s ease-in-out;
 }

 .whatsapp-icon:hover {
    transform: scale(1.12);
    opacity: 0.9;
 }

 @media screen and (max-width: 768px) {
    .whatsapp-icon {
        left: 20px;
    }
 }

 .loader-container {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
 }