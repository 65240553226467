.home-page {
    min-height: calc(100vh - 85px);
    width: 100%;
    position: relative;
}

.landing-section {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: calc(100vh - 85px);
    padding: 0px 60px;
    justify-content: space-between;
}

.landing-section-button-container {
    display: flex;
    align-items: center;
    width: 350px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    max-width: 100%;
}

.landing-section-mobile-bg {
    background-image: none;
    min-height: calc(100vh - 85px);
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and (max-width: 768px) {
    .landing-section-mobile-bg {
        position: relative;
    }

    .landing-section-mobile-bg::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(251, 245, 245, 0.55);
    } 
}


.landing-section-content-container {
    width: 50%;
}

.landing-section-heading {
    color: #3c3a3a;
    font-size: 43px;
    font-weight: 800;
    letter-spacing: 1.2px;
    line-height: 1.4;
    margin-bottom: 0px;
}


.landing-section-para {
    color: #302c2c;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.8px;
    margin-top: 25px;
    width: 85%;
}

.landing-image {
    border-radius: 25px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('../../assets/landingImage.jpg');
    height: 440px;
    width: 580px;
}


@media screen and (max-width: 768px) {
    .landing-section {
        padding: 120px 18px 80px;
        padding-right: 40px;
        min-height: calc(100vh - 85px);
        align-items: flex-start;
    }

    .landing-section-mobile-bg {
        background-image: url('../../assets/landingImage.jpg');
        background-position: 30% 40%;
    }

    .landing-section-content-container {
        width: 100%;
    }

    .landing-section-heading {
        font-size: 32px;
        color: #221d1d;
        margin-bottom: 30px;
    }

    .landing-section-para {
        font-size: 18px;
        color: #221d1d;
        font-weight: 500;
        width: 100%;
    }

    .landing-image {
        display: none;
    }
}

@media screen and (min-width: 770px) and (max-width: 1170px) {
    .landing-section {
        padding: 0px 30px;
    }

    .landing-section-heading {
        font-size: 34px;
    }

    .landing-section-content-container {
        width: 60%;
    }

    .landing-image {
        height: 300px;
        /* width: 270px; */
        max-width: 40%;
    }
}

.why-choose-us {
    width: 100%;
    padding: 0px 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 80vh;
}

.why-choose-heading {
    color: green;
    display: inline-block;
    font-size: 25px;
    padding-bottom: 8px;
    border-bottom: solid 3px green;
    margin-bottom: 20px;
}

.why-choose-heading2 {
    color: green;
    display: inline-block;
    font-size: 25px;
    padding-bottom: 8px;
    border-bottom: solid 3px green;
    margin-bottom: 20px;
    margin-left: 100px;
}


.why-choose-shadow-container {
    display: flex;
    align-items: flex-start;
    padding: 20px 25px;
    width: 90%;
    height: 140px;
    transition: all 1s;
    border-radius: 10px;
    margin-bottom:15px;
}

.why-choose-sub-heading {
    color: #3c3a3a;
    font-size: 23px;
    margin: 0px;
    margin-bottom: 15px;
}

.why-choose-para {
    color: #302c2c;
    line-height: 1.7;
    margin: 0px;
}

.check-button {
    background-color: #ffffff;
    border: none;
    color: green;
    height: 45px;
    width: 45px;
    padding: 14px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-right: 28px;
    transition: all 0.3s;
    box-shadow: 0px 0px 20px -7px #575454;
}

.why-choose-shadow-container:hover {
    -webkit-box-shadow: -3px 12px 75px -32px rgba(33,29,33,1);
    -moz-box-shadow: -3px 12px 75px -32px rgba(33,29,33,1);
    box-shadow: -3px 12px 75px -32px rgba(33,29,33,1);
}

.why-choose-shadow-container:hover .check-button {
    background-color: green;
    color: #ffffff;
}

.whychoose-image-container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.why-choose-image1 {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('../../assets/whychoose1.jpg');
    height: 340px;
    width: 80%;
    border-radius: 25px;
    transform: translate(70px, -70px);
}

.why-choose-image2 {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('../../assets/IMG-20240216-WA0006.jpg');
    height: 270px;
    width: 70%;
    border-radius: 25px;
    transform: translate(-30px, 100px);
    z-index: 3;
}

@media screen and (max-width: 768px) {
    .why-choose-us {
        padding: 0px 18px;
        align-items: center;
    }

    .why-choose-heading2 {
        margin-left: 18px;
    }

    .whychoose-image-container {
        display: none;
    }

    .why-choose-shadow-container {
        width: 100%;
        height: auto;
        padding: 20px 0px;
        padding-left: 10px;
    }

    .check-button {
        margin-right: 20px;
    }
}

@media screen and (min-width: 770px) and (max-width: 1170px) { 
    .why-choose-us {
        padding: 0px 30px;
    }

    .why-choose-heading {
        font-size: 23px;
    }

    .why-choose-heading2 {
        font-size: 23px;
        margin-left: 30px;
    }

    .why-choose-shadow-container {
        width: 100%;
        padding: 20px 10px;
        height: auto;
    }

    .why-choose-sub-heading {
        font-size: 21px;
        margin-bottom: 13px;
    }

    .check-button {
        margin-right: 25px;
        height: 40px;
        width: 40px;
    }

    .why-choose-image1 {
        width: 260px;
        height: 260px;
    }

    .why-choose-image2 {
        width: 190px;
        height: 190px;
    }
}

.product-section {
    width: 100%;
    min-height: 100vh;
}

.product-section-image-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.product-section-image1 {
  background-image: url('../../assets/https___www.solar-payback.com_gallery_photos_full_Turkey_Sunstrip_solarbooster_agriculture1.jpeg');
  height: 100%;
  flex-grow: 1;
  background-repeat: no-repeat;
  background-size: cover;
  filter: grayscale(1);
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product-section-image2 {
    background-image: url('../../assets/https___www.solar-payback.com_gallery_photos_full_Solarwall_Spain_Spain_SolarwallSpain_Foodandbeverage_2.png');
    height: 100%;
    background-repeat: no-repeat;
    flex-grow: 1;
    background-size: cover;
    filter: grayscale(1);
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.product-section-image3 {
    background-image: url('../../assets/https___www.solar-payback.com_gallery_photos_full_Mexico_Inventive_Power_Food_and_Beverage_6.jpg');
    height: 100%;
    background-repeat: no-repeat;
    flex-grow: 1;
    background-size: cover;
    filter: grayscale(1);
    transition: all 0.4s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.product-section-image1:hover {
    filter: grayscale(0);
    flex-grow: 1.2;
}

.product-section-image2:hover {
    filter: grayscale(0);
    flex-grow: 1.2;
}

.product-section-image3:hover {
    filter: grayscale(0);
    flex-grow: 1.2;
}

.product-heading {
    color: #ffffff;
    display: inline-block;
    text-align: center;
    width: 300px;
    height: 70px;
}

@media screen and (max-width: 768px) {
    .product-section-image-container {
        flex-direction: column;
    }

    .product-section-image1, .product-section-image2, .product-section-image3 {
        width: 100%;
        filter: grayscale(0);
    }

    .product-heading {
        font-size: 26px;
    }

}

@media screen and (min-width: 770px) and (max-width: 1170px) {
    .product-section-image-container {
        flex-direction: column;
    }

    .product-section-image1, .product-section-image2, .product-section-image3 {
        width: 100%;
        filter: grayscale(0);
    }

    .product-heading {
        font-size: 26px;
    }
}


.join-us-section {
    width: 100%;
    padding: 75px 0px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.joinus-heading {
    color: green;
    font-size: 30px;
    margin-bottom: 12px; 
}

.joinus-para {
    color: #302c2c;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.7;
    letter-spacing: 0.8px;
    width: 65%;
}

@media screen and (max-width: 768px) {
    .join-us-section {
        padding: 75px 18px 80px;
    }

    .joinus-heading {
        font-size: 26px;
        line-height: 1.5;
        margin-bottom: 0px;
    }

    .joinus-para {
        width: 100%;
    }
}

@media screen and (min-width: 770px) and (max-width: 1170px) {
    .joinus-para {
        width: 75%;
    }

    .join-us-section {
        padding-top: 70px;
    }
}

.achievement-section {
    width: 100%;
    padding: 80px 100px 40px;
}

/* .achievement-parent-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.achievement-section-content-container {
    width: 50%;
}

.achievement-section-slider-container {
    width: 45%;
} */


/* @media screen and (min-width: 770px) and (max-width: 1170px) {
    .achievement-section-slider-container {
        width: 48%;
    }
} */

/* .achievement-image-container {
    width: 100%;
}

.achievement-image {
    width: 100%;
    height: 300px;
} */

/* @media screen and (max-width: 768px) {
    .achievement-parent-container {
        flex-direction: column;
    }

    .achievement-section-content-container {
        width: 100%;
        margin-bottom: 10px;
    }

    .achievement-section-slider-container {
        width: 100%;
    }

    .achievement-image {
        height: 250px;
    }
} */

.sdg-heading {
    color: green;
    display: inline-block;
    font-size: 25px;
    padding-bottom: 8px;
    border-bottom: solid 3px green;
    margin-bottom: 20px;
}

.sdg-para {
    color: #302c2c;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.7;
    letter-spacing: 0.8px;
    width: 100%;
    /* margin-top: 10px; */
}

.sdg-goals-container {
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.sdg-item {
    background-repeat: no-repeat;
    background-size: cover;
    width: 250px;
    height: 250px;
    position: relative;
}


.sdg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.35);
    display: none;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .sdg-goals-container {
        flex-direction: column;
        justify-content: center;
    }

    .sdg-item {
        margin-bottom: 30px;
        max-width: 100%;
    }

    /* .sdg-overlay {
        display: flex;
        background-color: rgba(0, 0, 0, 0.15);
    } */
}

.sdg-item:hover .sdg-overlay {
    display: flex;
}

.sdg-item-7 {
    background-image: url('../../assets/E_WEB_07.png');
}

.sdg-item-9 {
    background-image: url('../../assets/E_WEB_09.png');
}

.sdg-item-12 {
    background-image: url('../../assets/E_WEB_12.png');
}


/* .achievement-container {
    display: flex;
    align-items: center;
} */
/* 
.achievement-icon {
    width: 5%;
} 

.achievement {
    font-size: 26px;
    color: #302c2c;
    display: flex;
    align-items: center;
    width: 95%;
} */

 @media screen and (max-width: 768px) { 
    .achievement-section {
        padding: 80px 18px 50px;
    }

    /* .achievement-icon {
        width: 10%;
    }
    
    .achievement {
        font-size: 19px;
        line-height: 1.7;
        width: 90%;
    } */
} 

@media screen and (min-width: 770px) and (max-width: 1170px) {
    .achievement-section {
        padding: 70px 30px 70px;
    }

    .sdg-heading {
        font-size: 23px;
    }

    /* .achievement {
        font-size: 22px;
    } */
}