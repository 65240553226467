.not-found-container {
    height: 100vh;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.not-found-image {
    width: 40%;
}

.not-found-para {
    color: #3a3c3c;
    font-size: 23px;
    font-weight: 500;
    margin-top: 27px;
    margin-bottom: 27px;
}

.not-found-link-element {
    color: rgb(27, 85, 232);
    font-size: 18px;
    font-weight: 500;
}

@media screen and (max-width: 768px) {
    .not-found-image {
        width: 90%;
    }
}

@media screen and (min-width: 770px) and (max-width: 1170px) {
    .not-found-image {
        width: 55%;
    }
}