.founding-page {
    min-height: calc(100vh - 85px);
    width: 100%;
}

.founding-page-bg {
    background-image: url('../../assets/founding-page.jpeg');
    height: 50vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.founding-page-bg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35);
}

.founding-page-main-heading {
    color: #f1f1f1;
    font-size: 39px;
    letter-spacing: 0.75px;
    line-height: 1.4;
    font-weight: 700;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none; 
    -ms-user-select: none; 
    z-index: 2;
}

.founding-page-content-container {
    padding: 60px 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.founding-page-main-para {
    color: #302c2c;
    font-size: 19px;
    font-weight: 400;
    line-height: 1.7;
    letter-spacing: 0.8px;
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
}

.founding-page-heading {
    color: green;
    margin-top: 30px;
    display: inline-block;
    font-size: 25px;
    padding-bottom: 8px;
    border-bottom: solid 3px green;
}

.founding-page-para {
    color: #302c2c;
    line-height: 1.7;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.5px;
}


.time-line-container {
    display: flex;
    flex-direction: column;
    width: 60%;
}

@media screen and (max-width: 768px) {
    .founding-page-main-heading {
        font-size:32px;
    }

    .founding-page-main-para {
        margin-bottom: 30px;
        font-size: 18.5px;
    }

    .founding-page-bg {
        height: 45vh;
    }
    
    .founding-page-para {
        font-size: 18px;
    }

    .founding-page-content-container {
        padding: 40px 18px;
    }

    .time-line-container {
        width: 100%;
    }
}

@media screen and (min-width: 770px) and (max-width: 1170px) {
    .founding-page-main-heading {
        font-size: 32px;
    }

    .founding-page-heading {
        font-size: 23px;
    }

    .founding-page-content-container {
        padding: 60px 40px;
    }

    .time-line-container {
        width: 85%;
    }
}