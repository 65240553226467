.footer {
    padding: 50px 0px 15px;
    background-color: #302e2e;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.footer-image {
    text-align: center;
    width: 130px;
    height: 130px;
    margin-bottom: 10px;
}

.footer-heading {
    color: #f1f1f1;
    text-align: center;
    font-size: 30px;
    letter-spacing: 0.9px;
    margin-bottom: 80px;
}

.address-container {
    display: flex;
    margin-bottom: 30px;
    width: 100%;
    padding-left: 100px;
}

.address-container2 {
    display: flex;
    margin-bottom: 30px;
    width: 100%;
    align-items: center;
    padding-left: 100px;
}

.address-para {
    color: #f1f1f1;
    margin: 0px;
    font-size: 16px;
    width: 95%;
    margin-left: 10px;
    line-height: 1.6;
    letter-spacing: 0.8px;
}

.footer-contact-para {
    color: #f1f1f1;
    font-size: 16px;
    line-height: 1.6;
    text-decoration: underline;
    margin: 0px;
    margin-left: 10px;
    letter-spacing: 1px;
}

.copyright-para {
    display: flex;
    align-items: center;
    color: #b4b8b5;
    margin: 8px 0px;
}

.rocket-button {
    background-color: #f1f1f1;
    color: #242222;
    border: none;
    border-radius: 50%;
    height: 55px;
    width: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 170px;
    right: 40px;
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.4s ease-in-out;
}

.rocket-button:hover {
    transform: scale(1.1);
}

.footer-icon {
    width: 4%;
}

@media screen and (max-width: 768px) {
    .footer-heading {
        line-height: 1.6;
        font-size: 23px;
        margin-right: 18px;
        margin-left: 18px;
        margin-bottom: 60px;
    }

    .footer-image {
        height: 100px;
        width: 100px;
    }

    .address-container, .address-container2 {
        padding: 0px 18px;
    }

    .address-para {
        font-size: 17px;
    }

    .rocket-button {
        right: 20px;
    }

    .footer-icon {
        width: 8%;
    }
}

@media screen and (min-width: 769px) and (max-width: 1190px) {
    .address-container, .address-container2 {
        padding: 0px 30px;
    }

    .footer-heading {
        line-height: 1.6;
        margin-right: 10px;
        margin-left: 10px;
        font-size: 28px;
    }
}

.parent-anchor {
    color: rgb(16, 192, 16);
    text-decoration: underline;
    margin-left: 5px;
}